import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <footer className="page-footer">
                <div className="widget-wrap">
                    <div className="left-widget">
                        <div className="">
                            <h2 className="footer-site-name">StrideSavvy</h2>
                            <p className="footer-text">Our vision is to provide convenience and help increase your sales business. 
                            </p>
                            <div className="social-links">
                                <Link to="https://www.instagram.com"><svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11_4675)">
    <circle cx="19" cy="19" r="19" fill="url(#paint0_linear_11_4675)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1197 6.3335H13.8803C9.71888 6.3335 6.33334 9.71903 6.33334 13.8804V24.1199C6.33334 28.2813 9.71888 31.6668 13.8803 31.6668H24.1197C28.2811 31.6668 31.6667 28.2813 31.6667 24.1199V13.8804C31.6666 9.71903 28.2811 6.3335 24.1197 6.3335ZM29.1181 24.1199C29.1181 26.8804 26.8803 29.1183 24.1197 29.1183H13.8803C11.1198 29.1183 8.88188 26.8804 8.88188 24.1199V13.8804C8.88188 11.1199 11.1198 8.88203 13.8803 8.88203H24.1197C26.8803 8.88203 29.1181 11.1199 29.1181 13.8804V24.1199ZM19 12.448C15.3872 12.448 12.4479 15.3873 12.4479 19.0001C12.4479 22.6129 15.3872 25.5522 19 25.5522C22.6128 25.5522 25.5521 22.6129 25.5521 19.0001C25.5521 15.3873 22.6128 12.448 19 12.448ZM19 23.0037C16.7889 23.0037 14.9964 21.2113 14.9964 19.0001C14.9964 16.789 16.7889 14.9966 19 14.9966C21.2111 14.9966 23.0036 16.789 23.0036 19.0001C23.0036 21.2112 21.2111 23.0037 19 23.0037ZM27.1348 12.4973C27.1348 13.3644 26.4319 14.0673 25.5648 14.0673C24.6977 14.0673 23.9948 13.3644 23.9948 12.4973C23.9948 11.6302 24.6977 10.9273 25.5648 10.9273C26.4319 10.9273 27.1348 11.6302 27.1348 12.4973Z" fill="white"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_11_4675" x1="7.71875" y1="38" x2="24.9375" y2="4.927e-07" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FBD06F"/>
    <stop offset="0.3" stop-color="#F16C2E"/>
    <stop offset="0.6" stop-color="#C82E96"/>
    <stop offset="1" stop-color="#4E6AD6"/>
    </linearGradient>
    <clipPath id="clip0_11_4675">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </Link>
                                <Link to="https://www.x.com"><svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11_4676)">
    <path d="M0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19Z" fill="#349DF0"/>
    <path d="M19.0871 14.9246L19.1334 15.6779L18.3612 15.5857C15.5503 15.2321 13.0946 14.0329 11.0096 12.0189L9.99022 11.0196L9.72766 11.7576C9.17165 13.4026 9.52688 15.1398 10.6852 16.3083C11.303 16.954 11.164 17.0462 10.0983 16.6619C9.72766 16.5389 9.40332 16.4466 9.37243 16.4927C9.26432 16.6004 9.63499 17.9994 9.92844 18.5528C10.33 19.3215 11.1486 20.0749 12.0443 20.5207L12.8011 20.8743L11.9053 20.8897C11.0405 20.8897 11.0096 20.905 11.1022 21.2279C11.4111 22.2272 12.6312 23.288 13.9904 23.7492L14.9479 24.0721L14.1139 24.564C12.8784 25.2712 11.4266 25.6709 9.97477 25.7017C9.27976 25.7171 8.70831 25.7786 8.70831 25.8247C8.70831 25.9784 10.5926 26.8394 11.6891 27.1776C14.9788 28.1769 18.8863 27.7464 21.8208 26.0399C23.9058 24.8254 25.9908 22.4117 26.9638 20.0749C27.489 18.8296 28.0141 16.5542 28.0141 15.4627C28.0141 14.7555 28.0604 14.6633 28.9253 13.8177C29.435 13.3257 29.9138 12.7876 30.0064 12.6339C30.1609 12.3418 30.1454 12.3418 29.3578 12.6032C28.045 13.0644 27.8596 13.0029 28.5083 12.3111C28.9871 11.8191 29.5585 10.9274 29.5585 10.666C29.5585 10.6199 29.3269 10.6968 29.0643 10.8352C28.7863 10.9889 28.1685 11.2195 27.7052 11.3579L26.8712 11.6192L26.1144 11.1119C25.6974 10.8352 25.1105 10.5277 24.8016 10.4354C24.0139 10.2202 22.8092 10.251 22.0988 10.4969C20.1682 11.1888 18.9481 12.9721 19.0871 14.9246Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_11_4676">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </Link>
                                <Link to="https://www.facebook.com"><svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11_4674)">
    <path d="M38 19C38 8.50551 29.4945 0 19 0C8.50551 0 0 8.50551 0 19C0 28.4852 6.94688 36.3449 16.0312 37.7699V24.4922H11.207V19H16.0312V14.8141C16.0312 10.0529 18.8664 7.42188 23.2082 7.42188C25.2863 7.42188 27.4609 7.79297 27.4609 7.79297V12.4688H25.0637C22.7035 12.4688 21.9688 13.9346 21.9688 15.4375V19H27.2382L26.3959 24.4922H21.9688V37.7699C31.0531 36.3449 38 28.4852 38 19Z" fill="#1877F2"/>
    <path d="M26.3959 24.4922L27.2382 19H21.9687V15.4375C21.9687 13.9346 22.7035 12.4688 25.0637 12.4688H27.4609V7.79297C27.4609 7.79297 25.2863 7.42188 23.2082 7.42188C18.8664 7.42188 16.0312 10.0529 16.0312 14.8141V19H11.207V24.4922H16.0312V37.7699C16.9998 37.9221 17.9906 38 19 38C20.0094 38 21.0002 37.9221 21.9687 37.7699V24.4922H26.3959Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_11_4674">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
                                </svg></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <p className="sub-head">About</p>
                        <p><Link to="#">How it works</Link></p>
                        <p><Link to="#">Featured</Link></p>
                        <p><Link to="#">Partnership</Link></p>
                        <p><Link to="#">Business Relation</Link></p>
                    </div>
                    <div className="widget">
                        <p className="sub-head">Community</p>
                        <p><Link to="#">Events</Link></p>
                        <p><Link to="#">Blog</Link></p>
                        <p><Link to="#">Podcast</Link></p>
                        <p><Link to="#">Invite a friend</Link></p>
                    </div>
                    <div className="widget">
                        <p className="sub-head">Socials</p>
                        <p><Link to="#">Discord</Link></p>
                        <p><Link to="#">Instagram</Link></p>
                        <p><Link to="#">Twitter</Link></p>
                        <p><Link to="#">Facebook</Link></p>
                    </div>
                </div>
                <hr className="rule"></hr>
                <div className="site-ref">
                    <p className="footer-bottom-text">&#169;  StrideSavvy. All rights reserved</p>
                    <div className="foot-right">
                        <p><Link to="/">Privacy Policy</Link></p>
                        <p><Link to="/">Terms and Conditions</Link></p>
                    </div>
                </div>
            </footer>
     );
}
 
export default Footer;