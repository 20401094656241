import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const Checkout = () => {

    const history = useHistory();

    const goBack = () => {
        history.go(-1);
    }

    const orderItem = (e) => {
        document.getElementById("popup").style.display = "block";
        document.getElementById("popup").style.animation = "slideIn 3s";
        e.preventDefault();
    }
    return ( 
        <div className="checkout-body-wrap">
            <div className="head-covering">
            <div id="popup" className="success-msg order-confirm">
                <p><i className="fa fa-check-circle"></i><br /> Order Confirmed! <br /> Thank you for shopping.<br /> <br />
                <Link className="" to="/shop">Continue Shoping</Link></p>
            </div>
            <h2 className="page-title">
                <button onClick={goBack}><i className="fa fa-angle-left"></i></button>   Payment Method</h2>
            </div>
            <div className="row-container">
                <div className="check-left">
                    <span className="check-icon"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="check-right">
                    <div className="check-row1">
                        <span className="num-name">
                            1. Custumer Address
                        </span>
                        <span className="change">
                            Change <i className="fa fa-angle-right"></i>
                        </span>
                    </div>
                    <div className="check-row2">
                        <p className="customer-name">Alex Pedro</p>
                    </div>
                    <div className="customer-address">
                        <span>Beach Street, 123 Downtown Neighborhood  |</span>
                        <span>Rio de Janeiro - Brazil  |</span>
                        <span>+55 114 342 6781</span>
                    </div>
                </div>
            </div>
            <div className="row-container">
                <div className="check-left">
                    <span className="check-icon"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="check-right">
                    <div className="check-row1">
                        <span className="num-name">
                            2. Delivery Details
                        </span>
                        <span className="change">
                            Change <i className="fa fa-angle-right"></i>
                        </span>
                    </div>
                    <div className="check-row2">
                        <p className="customer-name">Door delivery</p>
                    </div>
                    <div className="customer-address">
                        <span>Delivery between <strong>21 Aug and 26 Aug</strong></span>
                    </div>
                </div>
            </div>
            <div className="row-container">
                <div className="check-left">
                    <span className="check-icon"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="check-right check3-change">
                    <div className="check-row1">
                        <span className="num-name">
                            3. Payment Method
                        </span>
                    </div>
                    <hr />
                    <div className="check-sub">
                        Payment on Delivery
                    </div>
                    <form className="radio-form">
                        <div className="check-row2">
                            <input type="radio" name="radio-payment" /> 
                            <span className="pay-del">Pay on Delivery</span>
                        </div>
                        <div className="radio-title">
                            <small>With cash, bank transfer or cards.</small>
                        </div>
                        <hr />
                        <div className="check-row2 check3-row2">
                            <span className="radio-btn-cover">
                                <input type="radio" name="radio-payment" />
                                <span className="pay-with">Pay with cards, Bank Transfers or USSD</span>
                            </span>
                        </div>
                            <div className="radio-title">
                                <small>You'll be redirected to our secure payment page</small>
                                <p>-Ensure your payment information is up to date and that you have the necessary funds.</p>
                                <p>-For bank transfer, kindly ensure you transfer the exact amount displayed.</p>
                                <p>-Payment confirmation may take up to 2 minutes.</p>
                                <div className="payment-channel">
                                    <span>We Accept</span> 
                                    <div className="channel-wrap">
                                        <img className="pay-img" alt="" src="images/payment.png" />
                                    </div>
                                </div>
                            </div>
                        <hr />
                        <div className="check-row2 check4">
                            <span className="install">Installment</span>
                            <span className="radio-btn-cover">
                                <input type="radio" name="radio-payment" /> 
                                <span className="stand">Standard Chartered Credit Card @3% interest - Up to 12 months</span>
                            </span>
                        </div>
                            <div className="pay-btn-cover">
                                <button className="pay-btn" onClick={orderItem} >Confirm Payment Details</button>
                            </div>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default Checkout;