import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Header from './Header';
import ProductList from './ProductList';
import Footer from './Footer';
import Cart from './Cart';
import Checkout from './Checkout';
import Home from './Home';
import NotFound from './NotFound';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="body">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/shop">
              <ProductList />
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/checkout">
              <Checkout />
            </Route>
            <Route exact path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
