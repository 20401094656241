import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Cart = () => {
    const history = useHistory();

    const goBack = () => {
        history.go(-1);
    }
    const removeItem = () => {
        document.getElementById("popup").style.display = "block";
        document.getElementById("popup").style.animation = "slideIn 3s";
        setTimeout(function(){
            document.getElementById("popup").style.display = "none";
          }, 2500);
    }
    return ( 
        <>
            <div className="head-covering">
            <h2 className="page-title">
                <button onClick={goBack}><i className="fa fa-angle-left"></i></button>   Cart (3)</h2>
            </div>
        <div className="page-cover">
            <div id="popup" className="success-msg removed">
                <p><i className="fa fa-trash"></i> Item Removed Successfully!</p>
            </div>
            <div className="cart-items-cover">
                <div className="cart-items">
                    <div className="col1">
                        <img className="product-image" src="images/9.png" alt="Product" />
                    </div>
                    <div className="item-cover">
                        <div className="slice-div">
                                <p className="item-count">1.</p>
                            <div className="product-details">
                                <div className="left">
                                    <p className="p-name">Nike Air Max</p>
                                    <p className="">Black and White</p>
                                    <p className="">Size: 42</p>
                                    <p className="">In Stock</p>
                                </div>
                                <div className="right">
                                    <p className="amount"><span className="">$130.00</span></p>
                                    <p className="discount"><span className="m-amount">$132.60</span> <span className="percent">-2%</span></p>
                                    <p className="link-share">
                                        <span className="link"><i className="fa fa-link"></i></span> <span className="link"><i className="fa fa-share-square"></i></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-cut">
                            <div className="bottom">
                                <div className="bottom-left">
                                    <button className="remove-btn" onClick={removeItem} >Remove <i className="fa fa-trash"></i> </button>
                                </div>
                                <div className="bottom-right">
                                    <button className="increment"> <i className="fa fa-minus"> </i></button>  1  <button className="decrement"><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cart-items">
                    <div className="col1">
                        <img className="product-image" src="images/2.png" alt="Product" />
                    </div>
                    <div className="item-cover">
                        <div className="slice-div">
                                <p className="item-count">2.</p>
                            <div className="product-details">
                                <div className="left">
                                    <p className="p-name">Nike Jordan 3</p>
                                    <p className="">Black and White</p>
                                    <p className="">Size: 42</p>
                                    <p className="">In Stock</p>
                                </div>
                                <div className="right">
                                    <p className="amount"><span className="">$150.00</span></p>
                                    <p className="discount"><span className="m-amount">$152.60</span> <span className="percent">-2%</span></p>
                                    <p className="link-share">
                                        <span className="link"><i className="fa fa-link"></i></span> <span className="link"><i className="fa fa-share-square"></i></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-cut">
                            <div className="bottom">
                                <div className="bottom-left">
                                    <button className="remove-btn" onClick={removeItem} >Remove <i className="fa fa-trash"></i> </button>
                                </div>
                                <div className="bottom-right">
                                    <button className="increment"> <i className="fa fa-minus"> </i></button>  1  <button className="decrement"><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cart-items">
                    <div className="col1">
                        <img className="product-image" src="images/4.png" alt="Product" />
                    </div>
                    <div className="item-cover">
                        <div className="slice-div">
                                <p className="item-count">3.</p>
                            <div className="product-details">
                                <div className="left">
                                    <p className="p-name">Nike Air 1</p>
                                    <p className="">White</p>
                                    <p className="">Size: 42</p>
                                    <p className="">In Stock</p>
                                </div>
                                <div className="right">
                                    <p className="amount"><span className="">$70.00</span></p>
                                    <p className="discount"> <span className="m-amount">$72.60</span> <span className="percent">-2%</span></p>
                                    <p className="link-share">
                                        <span className="link"><i className="fa fa-link"></i></span> <span className="link"><i className="fa fa-share-square"></i></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-cut">
                            <div className="bottom">
                                <div className="bottom-left">
                                    <button className="remove-btn" onClick={removeItem} >Remove <i className="fa fa-trash"></i> </button>
                                </div>
                                <div className="bottom-right">
                                    <button className="increment"> <i className="fa fa-minus"> </i></button>  1  <button className="decrement"><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="items-checkout">
                <p className="check-title">Cart Summary</p>
                <div className="sub-total">
                    <p className="sub">Subtotal</p>
                    <p className="checkout-amount"><strong>$305.00</strong></p>
                </div>
                <button className="checkout-btn"><Link to="/checkout"> Checkout ($305.00)</Link></button>
            </div>
        </div>
        </>
     );
}
 
export default Cart;