import { Link } from "react-router-dom";


const ProductList = () => {

    const addProduct = () => {
        
        document.getElementById("popup").style.display = "block";
        document.getElementById("popup").style.animation = "slideIn 3s";
        setTimeout(function(){
            document.getElementById("popup").style.display = "none";
          }, 2500);
    }

    return ( 
        <div className="cover">
            <div id="popup" className="success-msg">
                <p><i className="fa fa-check-circle"></i> Product added to Cart</p>
            </div>
            <div className="products-title">
                <div className="title-left">
                    <h1 className="list-title">Prime Kicks</h1>
                    <p className="title-text">Shoes that help you move in your prime.</p>
                </div>
                <div className="title-right">
                    <button className="filter">Filter <i className="fa fa-sliders"></i></button>
                </div>
            </div>
            <div className="products-cover">
                <div className="product-card">
                    <span className="product-like">
                        <svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg>
</span>
                    <div className="product-img">
                        <img src="images/1.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air</Link></p>
                        <p className="product-desc">Women's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$110.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/2.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air 1</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$130.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/3.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Jordan 3</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$150.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/4.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Sprint</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$80.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>

                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/5.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air Max</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$130.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/6.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air 1</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$70.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/7.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air Jordan 2</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$100.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/8.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air Jordan 1</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$165.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>

                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/9.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air Jordan 2</Link></p>
                        <p className="product-desc">Men's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$120.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/10.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Superstar</Link></p>
                        <p className="product-desc">Women's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$95.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/11.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air Jordan 5</Link></p>
                        <p className="product-desc">Women's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$190.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
                <div className="product-card">
                    <span className="product-like"><svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.4265 20.72C45.312 18.8048 46.3595 16.219 46.3385 13.5315C46.3175 10.844 45.2297 8.27487 43.3145 6.38936C42.3662 5.45575 41.2432 4.71807 40.0098 4.21843C38.7764 3.71879 37.4567 3.46698 36.126 3.47738C33.4384 3.49839 30.8693 4.58614 28.9838 6.50136C28.4718 7.01336 27.8212 7.64181 27.0318 8.38669L24.8372 10.4534L22.6425 8.38669C21.8514 7.64003 21.1998 7.01158 20.6878 6.50136C18.7875 4.601 16.21 3.53339 13.5225 3.53339C10.835 3.53339 8.25752 4.601 6.35715 6.50136C2.44249 10.4187 2.39716 16.752 6.21315 20.688L24.8372 39.312L43.4265 20.72ZM4.09315 4.24003C5.33132 3.00154 6.80133 2.0191 8.41923 1.34882C10.0371 0.678535 11.7712 0.333541 13.5225 0.333541C15.2737 0.333541 17.0078 0.678535 18.6257 1.34882C20.2437 2.0191 21.7137 3.00154 22.9518 4.24003C23.4372 4.72714 24.0656 5.33336 24.8372 6.05869C25.6052 5.33336 26.2336 4.72625 26.7225 4.23736C29.2039 1.7178 32.5845 0.28716 36.1207 0.260155C39.6568 0.23315 43.0589 1.61199 45.5785 4.09336C48.098 6.57473 49.5287 9.95535 49.5557 13.4915C49.5827 17.0277 48.2038 20.4298 45.7225 22.9494L26.7225 41.952C26.2224 42.452 25.5443 42.7328 24.8372 42.7328C24.1301 42.7328 23.4519 42.452 22.9518 41.952L3.94649 22.9467C1.50926 20.4331 0.158515 17.0617 0.185957 13.5606C0.213399 10.0596 1.61683 6.70978 4.09315 4.23469V4.24003Z" fill="#FF0000"/>
</svg></span>
                    <div className="product-img">
                        <img src="images/12.png" alt="Product" />
                    </div>
                    <div className="product-info">
                        <p className="product-name"><Link to="/view-product">Nike Air Jordan 3</Link></p>
                        <p className="product-desc">Women's Shoes</p>
                    </div>
                    <div className="price-button">
                        <p className="product-price">$130.00</p>
                        <button className="addCart-btn" onClick={addProduct}>Add to cart  <i className="fas fa-cart-plus"></i></button>
                    </div>
                </div>
            </div>
            <div className="product-paging">
                <button className="btn1"><i className="fas fa-angle-left"></i></button> 
                <button className="btn2">1</button> 
                <button className="btn3">of</button> 
                <button className="btn4">24</button> 
                <button className="btn5"><i className="fas fa-angle-right"></i></button>
            </div>
        </div>
     );
}
 
export default ProductList;