import { Link } from "react-router-dom";
const Home = () => {
    return ( 
        <div className="site-body">
            <div className="body-wrap">
                <h1 className="body-title">Shop with Ease</h1>
                <p className="body-text">Find your favorite shoe in no time</p>
                <Link className="start-shopping" to="/shop">Start Shopping</Link>
            </div>
        </div>
     );
}
 
export default Home;