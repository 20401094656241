import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
        <div className="not-found">
            <h2 className="notfound-text">Opss!!! This page does not exist...</h2>
            <div>
                <p className="go-home"> <Link to="/"> Home</Link> </p>
                <p className="go-home"> <Link to="/shop"> Product List</Link> </p>
                <p className="go-home"> <Link to="/cart"> Cart</Link> </p>
                <p className="go-home"> <Link to="/checkout"> Checkout</Link> </p>
            </div>
        </div>
     );
}
 
export default NotFound;