import { Link } from "react-router-dom";

const Header = () => {

    const openSideNav = () => {
        let navButton = document.querySelector(".site-details2");
        let navSection = document.querySelector(".side-nav");
        navButton.onclick = function(){
            navSection.classList.add('show-sidenav');
        }
    }

    const closeSideNav = () => {
        let navButton = document.querySelector(".close-sidenav");
        let navSection = document.querySelector(".side-nav");
        navButton.onclick = function(){
            navSection.classList.remove('show-sidenav');
        }
    }
    return ( 
        <header className="site-header">
        <Link to="/" className="site-details">
            <h2 className="site-name">StrideSavvy</h2>
        </Link>
        <div className="didi">
            <button onClick={openSideNav} className="site-details2">
                <h2 className="site-name"><i className="fa fa-bars"></i></h2>
            </button>
            <h2><Link to="/">StrideSavvy</Link></h2>
        </div>

        <nav className="site-nav">
            <ul className="nav-wrap">
                <li><Link to="/profile"><i className='fas fa-user'></i> Hi, Pedro  <i className="fas fa-angle-down"></i></Link></li>
                <li className="bordered"><Link to="/cart"><i className='fas fa-cart-shopping'></i> <span className="cart-count">3</span> Cart</Link></li>
            </ul>
        </nav>
        <nav className="side-nav">
            <div className="nav-top">
                <button onClick={closeSideNav} className="close-sidenav" ><i className="fa fa-times"></i></button>
                <h3 className="side-title">StrideSavvy</h3>
            </div>
            <div className="nav-mid">
                    <li><Link to=""> <i className="fa fa-home"></i> Home</Link></li>
                    <li><Link to=""><i className="fa fa-user"> </i> My Account</Link></li>
                    <li><Link to=""><i className="fa fa-database"></i> Orders</Link></li>
                    <li><Link to=""><i className="fa fa-credit-card"></i> Payments</Link></li>
                    <li><Link to=""><i className="fa fa-user-times"></i> Close Account</Link></li>
                    <li><Link to=""><i className="fa fa-phone"></i> Contact Us</Link></li>
            </div>
            <div className="nav-bottom">
                <li><Link to=""><i className="fa fa-sign-out"></i> Logout</Link></li>
            </div>
        </nav>
        <nav className="site-nav2">
            <ul className="nav-wrap">
                <li><Link to="/profile"><i className='fas fa-user'></i> <i className="fas fa-angle-down"></i></Link></li>
                <li className="bordered"><Link to="/Cart"><i className='fas fa-cart-shopping'></i> <span className="cart-count">3</span> </Link></li>
            </ul>
        </nav>
    </header>
     );
}
 
export default Header;